export const useFields = () => {
  return [
    '*',

    'image.id', 'image.filename_download', 'image.description', 'image.width', 'image.height',

    'categories.category_id.slug', 'categories.category_id.title',

    'hero.*', 
    'hero.cta.cta_id.*', 
    'hero.image.id', 'hero.image.filename_download', 'hero.image.description', 'hero.image.width', 'hero.image.height',
    'hero.lottie.id', 'hero.lottie.filename_download',

    'dynamic.collection',

    'dynamic.item.items.resource_id.*',

    'dynamic.item.items.usp_id.*', 'dynamic.item.items.usp_id.image.id', 'dynamic.item.items.usp_id.image.filename_download', 'dynamic.item.items.usp_id.image.description', 'dynamic.item.items.usp_id.image.width', 'dynamic.item.items.usp_id.image.height',

    'dynamic.item.*',
    'dynamic.item.images.directus_files_id.id', 'dynamic.item.images.directus_files_id.filename_download', 'dynamic.item.images.directus_files_id.description', 'dynamic.item.images.directus_files_id.width', 'dynamic.item.images.directus_files_id.height',
    'dynamic.item.image.id', 'dynamic.item.image.filename_download', 'dynamic.item.image.description', 'dynamic.item.image.width', 'dynamic.item.image.height',
    'dynamic.item.lottie.id', 'dynamic.item.lottie.filename_download',
    
    'dynamic.item.items.*',
    'dynamic.item.items.bricks_item_id.*', 'dynamic.item.items.bricks_item_id.image.id', 'dynamic.item.items.bricks_item_id.image.filename_download', 'dynamic.item.items.bricks_item_id.image.description', 'dynamic.item.items.bricks_item_id.image.width', 'dynamic.item.items.bricks_item_id.image.height', 'dynamic.item.items.bricks_item_id.lottie.id', 'dynamic.item.items.bricks_item_id.lottie.filename_download', 'dynamic.item.items.bricks_item_id.cta.*',
    'dynamic.item.items.features_item_id.*', 'dynamic.item.items.features_item_id.image.id', 'dynamic.item.items.features_item_id.image.filename_download', 'dynamic.item.items.features_item_id.image.description', 'dynamic.item.items.features_item_id.image.width', 'dynamic.item.items.features_item_id.image.height', 'dynamic.item.items.features_item_id.lottie.id', 'dynamic.item.items.features_item_id.lottie.filename_download',
    'dynamic.item.items.compliance_item_id.*', 'dynamic.item.items.compliance_item_id.image.id', 'dynamic.item.items.compliance_item_id.image.filename_download', 'dynamic.item.items.compliance_item_id.image.description', 'dynamic.item.items.compliance_item_id.image.width', 'dynamic.item.items.compliance_item_id.image.height', 'dynamic.item.items.compliance_item_id.lottie.id', 'dynamic.item.items.compliance_item_id.lottie.filename_download',

    'dynamic.item.cta.cta_id.*',

    'dynamic.item.item.testimonial_id.*', 'dynamic.item.item.testimonial_id.image.id', 'dynamic.item.item.testimonial_id.image.filename_download', 'dynamic.item.item.testimonial_id.image.description', 'dynamic.item.item.testimonial_id.image.width', 'dynamic.item.item.testimonial_id.image.height',

    'dynamic.item.faqs.*', 'dynamic.item.faqs.faq_item_id.*',
    'dynamic.item.articles.*', 'dynamic.item.articles.article_id.*', 'dynamic.item.articles.article_id.image.id', 'dynamic.item.articles.article_id.image.filename_download', 'dynamic.item.articles.article_id.image.description', 'dynamic.item.articles.article_id.image.width', 'dynamic.item.articles.article_id.image.height',
    'dynamic.item.stat.*',

    'seo.meta_title', 'seo.meta_description', 'seo.meta_image.id', 'seo.meta_image.filename_download'

  ]
}